@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-black: #000;
  --color-white: #fff;
  --color-gray-steel: #091427;
  --color-gray-ink: #34404e;
  --color-gray-cloud: #8894ac;
  --color-gray-rain: #cdd5e5;
  --color-gray-snow: #ecf1f5;
  --color-gray-pale: #f6f9ff;
  --color-gray-light: #fafafa;

  --color-main-rain: #3eab7a;
  --color-main-sw: #50c08e;
  --color-main-pale: #7acca8;
  --color-main-light: #e0f4eb;

  --color-green: #0dc44e;
  --color-green-10: #eef9f4;
  --color-green-120: #3cb07c;
  --color-light-green: #e6fdee;
  --color-red: #f24660;
  --color-light-red: #fff0f3;
  --color-blue: #46b6f2;
  --color-orange: #f79419;

  --font-family-ja: 'Hiragino Kaku Gothic ProN', 'Noto Sans JP', Meiryo,
    メイリオ, sans-serif;
  --font-size: 14px;

  --height-header: 60px;

  --line-height: 1.65;

  --inset-x-content: 32px;

  --width-body: 1260px;
  --width-container: 752px;
  --width-side: 336px;
}

html {
  color: var(--color-black);
  font-size: var(--font-size);
  font-family: var(--font-family-ja);
  height: 100%;
  line-height: var(--line-height);
}

body {
  background: var(--color-white);
  display: flex;
  flex-direction: column;
  min-height: 100%;
  min-width: var(--width-body);
  overflow-y: scroll;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

#root {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 0;
  position: relative;
}

a {
  color: var(--color-blue);
}

*:focus,
button:focus {
  outline: none;
}
